var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        [
          _c("FilenameOption", {
            model: {
              value: _vm.filename,
              callback: function ($$v) {
                _vm.filename = $$v
              },
              expression: "filename",
            },
          }),
          _c("AutoWidthOption", {
            model: {
              value: _vm.autoWidth,
              callback: function ($$v) {
                _vm.autoWidth = $$v
              },
              expression: "autoWidth",
            },
          }),
          _c("BookTypeOption", {
            model: {
              value: _vm.bookType,
              callback: function ($$v) {
                _vm.bookType = $$v
              },
              expression: "bookType",
            },
          }),
          _c(
            "el-button",
            {
              staticStyle: { margin: "0 0 20px 20px" },
              attrs: {
                loading: _vm.downloadLoading,
                type: "primary",
                icon: "el-icon-document",
              },
              on: { click: _vm.handleDownload },
            },
            [_vm._v("\n      Export Excel\n    ")]
          ),
          _c(
            "a",
            {
              staticStyle: { "margin-left": "15px" },
              attrs: {
                href: "https://panjiachen.github.io/vue-element-admin-site/feature/component/excel.html",
                target: "_blank",
              },
            },
            [
              _c("el-tag", { attrs: { type: "info" } }, [
                _vm._v("Documentation"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.dataList,
            "element-loading-text": "Loading...",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "Id", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.$index) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Title" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.pro2) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Author", width: "110", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("el-tag", [_vm._v(_vm._s(scope.row.pro3))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Readings", width: "115", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.pageviews) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }